import request from '@/utils/request'

// 用户登录
export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

// 用户退出
export function logout(data) {
  return request({
    url: 'auth/logout',
    method: 'post',
    data
  })
}

// 获取用户拥有菜单
export function getMenu(data){
  return request({
    url: 'auth/getMenus',
    method: 'post',
    data
  })
}

// 获取用户信息
export function getInfo(token) {
  return request({
    url: 'dev-api/vue-element-admin/user/info',
    method: 'get',
    params: { token }
  })
}

// 修改用户密码
export function setUserPwd(data){
  return request({
    url:'auth/setPassword',
    method:'post',
    data
  })
}

// 获取手机号验证码
export function send(data) {
  return request({
    url: 'auth/send',
    method: 'get',
    params: data
  })
}

// 忘记密码-获取手机号
export function sendSmsCode(data) {
  return request({
    url: '/auth/sendSmsCode',
    method: 'get',
    params: data
  })
}


// 忘记密码
export function passwordReset(data) {
  return request({
    url: '/auth/passwordReset',
    method: 'post',
    data
  })
}

// 字典获取
export function getDicOptions(dicOption) {
  return request({
    url: '/dict/index',
    method: 'get',
    params: { type: dicOption }
  })
}

// 行政区划
export function getRegion(data) {
  return request({
    url: '/city/index',
    method: 'get',
    params: data
  })
}

// 部门列表
export function getDeptList(data) {
  return request({
    url: '/dept/index',
    method: 'get',
    params: data
  })
}

// 获取分配销售人员
export function getDeptUser(data) {
  return request({
    url: '/user/getUserInfoToDept',
    method: 'get',
    params: data
  })
}

// 获取审核人
export function sendProcessName(data) {
  return request({
      url: '/notice/sendProcessName',
      method: 'post',
      data
  })
}

// 获取组织架构树
export function getUserTree(data) {
  return request({
    url: '/user/get_tree',
    method: 'get',
    params: data
  })
}

// 获取本人信息
export function getUserInfo(data) {
  return request({
    url: '/user/getUserInfo',
    method: 'get',
    params: data
  })
}

// 获取销售客户单位列表
export function getList(data) {
  return request({
      url: '/customer_maintenance/getList',
      method: 'get',
      params: data
  })
}

// 获取请假人
export function getLeaveList(data) {
  return request({
      url: '/leave/getList',
      method: 'get',
      params: data
  })
}

// 获取部门领导
export function getLeaderList(data) {
  return request ({
    url: '/user/getLeaderList',
    method: 'get',
    params: data
  })
}