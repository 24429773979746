import {login, logout, getInfo} from '@/api/user'
import {setInfo, removeInfo, setToken, getToken, removeToken, setCode, removeCode} from '@/utils/auth'
import router, {resetRouter} from '@/router'

const state = {
    userInfo: {},
    name: '',
    avatar: '',
    introduction: '',
    roles: [],
    token: getToken()
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_UserInfo: (state, info) => {
        state.userInfo = info
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    }
}

const actions = {
    // user login
    login({commit}, userInfo) {
        return new Promise((resolve, reject) => {
            removeToken()
            removeCode()
            login(userInfo).then(response => {
                if (response.code === 0) {
                    let token = response.data.token
                    let code = response.data.code
                    commit('SET_UserInfo', response.data)
                    commit('SET_TOKEN', token)
                    setToken(token)
                    setCode(code)
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    getInfo({commit, state}, role = ["editor"]) {
        return new Promise((resolve, reject) => {
            commit('SET_ROLES', role)
            resolve({
                roles: role
            })
        })
    },

    // user logout
    logout({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            logout(state.token).then((res) => {
                if (res.code == 0) {
                    removeInfo()
                    removeToken()
                    removeCode()
                    resolve()
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeInfo()
            resolve()
        })
    },

    // dynamically modify permissions
    async changeRoles({commit, dispatch}, role) {
        const token = role + '-token'

        commit('SET_TOKEN', token)
        setToken(token)

        const {roles} = await dispatch('getInfo', role)

        resetRouter()

        // generate accessible routes map based on roles
        const accessRoutes = await dispatch('permission/generateRoutes', roles, {root: true})
        // dynamically add accessible routes
        router.addRoutes(accessRoutes)

        // reset visited views and cached views
        dispatch('tagsView/delAllViews', null, {root: true})
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
