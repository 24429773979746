import { NavBar, Image as VanImage, Checkbox, Steps, Step, Collapse, CollapseItem, SwipeCell, Lazyload, Uploader, Picker, RadioGroup, Radio, Loading, Grid, GridItem, Popup, DatetimePicker, Dialog, NoticeBar, Image, Divider, Tag, CellGroup, Search, Row, Col, Badge, Form, Field, Button, Tabbar, TabbarItem, Icon, Tab, Tabs, Cell, List, PullRefresh, ActionSheet, Toast } from 'vant'
import Vue from 'vue'

Vue.use(VanImage)
Vue.use(Lazyload) // 图片懒加载
Vue.use(Loading)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(Dialog)
Vue.use(Image)
Vue.use(CellGroup)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(Search)
Vue.use(Row)
Vue.use(Col)
Vue.use(Badge)
Vue.use(ActionSheet)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Cell)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Tabbar)
Vue.use(Icon)
Vue.use(TabbarItem)
Vue.use(Form)
Vue.use(Field)
Vue.use(NavBar)
Vue.use(Button)
Vue.use(NoticeBar)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Picker)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Uploader)
Vue.use(SwipeCell)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Checkbox)