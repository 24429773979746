import Cookies from 'js-cookie'
// import router from "@/router";
const userKey = 'Admin-Info'

const TokenKey = 'Admin-Token'
const CodeKey = 'Admin-Code'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getCode () {
  return Cookies.get(CodeKey)
}

export function setCode (code) {
  return Cookies.set(CodeKey, code)
}

export function removeCode () {
  return Cookies.remove(CodeKey)
}

export function getInfo () {
  return sessionStorage.getItem(userKey)
}

export function setInfo (info) {
  return sessionStorage.setItem(userKey, JSON.stringify(info))
}

export function removeInfo () {
  return sessionStorage.removeItem(userKey)
}

export function getRouters () {
  return sessionStorage.getItem('routerData')
}

export function setRouters (routers) {
  return sessionStorage.setItem('routerData', JSON.stringify(routers))
}

export function removeRouters () {
  return sessionStorage.removeItem('routerData')
}
