import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login' // 重定向到首页
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "index_login" */ '@/views/Login/index_login.vue')
    // boforeEnter (to, from, next) {
    //   if (getToken()?.length > 0 && to.path === '/login') {
    //     // 有token且是去login页，不放行
    //     // next(false)
    //     next('/layout/home')
    //   } else {
    //     next() // 其他情况放行
    //   }
    // }
  },
  {
    path: '/login/update',
    component: () => import("@/views/Login/loginUpdate.vue")
  },
  {
    path: '/layout',
    component: () => import(/* webpackChunkName: "index_layout" */ '@/views/Layout/index_layout.vue'),
    children: [
      {
        path: 'home',
        component: () => import(/* webpackChunkName: "index_home" */ '@/views/Home/index_home.vue'),
        meta: {
          scrollT: 0 // 滚动距离
        }
      },
      {
        path: 'homeDetail',
        component: () => import('@/views/Home/homeDeatail.vue')
      },
      {
        path: 'notice',
        component: () => import('@/views/Notice/list.vue')
      },
      {
        path: 'audit',
        component: () => import('@/views/Audit/list.vue')
      },
      {
        path: 'user',
        component: () => import(/* webpackChunkName: "index_user" */ '@/views/User/index_user.vue')
      },
      // 工艺文件
      {
        path: '/craftFile',
        component: () => import("@/views/craftFile/list.vue")
      },
      {
        path: '/craftFile/view',
        component: () => import("@/views/craftFile/view.vue")
      },
      // 请假
      {
        path: '/leave',
        component: () => import("@/views/Leave/list.vue")
      },
      {
        path: '/leave/add',
        component: () => import("@/views/Leave/add.vue")
      },
      {
        path: '/leave/view',
        component: () => import("@/views/Leave/view.vue")
      },
      // 调休
      {
        path: '/rest',
        component: () => import("@/views/Rest/list.vue")
      },
      {
        path: '/rest/add',
        component: () => import('@/views/Rest/add.vue')
      },
      {
        path: '/rest/view',
        component: () => import('@/views/Rest/view.vue')
      },
	  // 加班
	  {
	    path: '/overTime',
	    component: () => import("@/views/OverTime/list.vue")
	  },
	  {
	    path: '/overTime/add',
	    component: () => import('@/views/OverTime/add.vue')
	  },
	  {
	    path: '/overTime/view',
	    component: () => import('@/views/OverTime/view.vue')
	  },
      // 加工单
      {
        path: '/bomOrder',
        component: () => import("@/views/Production/BomOrder/primaryList.vue")
      },
      {
        path: '/bomOrder/view',
        component: () => import("@/views/Production/BomOrder/view.vue")
      },
      // 发货单
      {
        path: '/sendGoodsOrder',
        component: () => import("@/views/Production/sendGoodsOrder/list.vue")
      },
      {
        path: '/sendGoodsOrder/view',
        component: () => import("@/views/Production/sendGoodsOrder/view.vue")
      },
      // 公告详情
      {
        path: '/notice/view',
        component: () => import('@/views/Notice/view.vue')
      },
      // 线索列表
      {
        path: '/clue',
        component: () => import("@/views/Clue/list.vue"),
      },
      {
        path: '/clue/view',
        component: () => import("@/views/Clue/view.vue")
      },
      // 销售下单
      {
        path: '/placeAnOrder',
        component: () => import("@/views/Market/placeAnOrder/list.vue")
      },
      // 销售下单查看
      {
        path: '/placeAnOrder/view',
        component: () => import("@/views/Market/placeAnOrder/view.vue")
      },
	  // 发货图片上传
	  {
	    path: '/placeAnOrder/imageUplate',
	    component: () => import("@/views/Market/placeAnOrder/imageUplate.vue")
	  },
	  // 发货图片查看
	  {
	    path: '/placeAnOrder/imageView',
	    component: () => import("@/views/Market/placeAnOrder/imageView.vue")
	  },
      // 库房中心
        // 入库单
      {
        path: '/stockIn',
        component: () => import("@/views/warehouse/stockIn/list.vue")
      },
      {
        path: '/stockIn/view',
        component: () => import("@/views/warehouse/stockIn/view.vue")
      },
        // 出库单
      {
        path: '/stockOut',
        component: () => import("@/views/warehouse/stockOut/list.vue")
      },
      {
        path: '/stockOut/view',
        component: () => import("@/views/warehouse/stockOut/view.vue")
      },
      // 质检单
      {
        path: '/qualityTesting',
        component: () => import("@/views/warehouse/qualityTesting/list.vue")
      },
      {
        path: '/qualityTesting/view',
        component: () => import("@/views/warehouse/qualityTesting/view.vue")
      },
      // 到货单
      {
        path: '/arrivalOrder',
        component: () => import("@/views/warehouse/arrivalOrder/list.vue")
      },
      {
        path: '/arrivalOrder/view',
        component: () => import("@/views/warehouse/arrivalOrder/view.vue")
      },
      // 审核详情
      {
        path: '/audit/view',
        component: () => import('@/views/Audit/component/view.vue')
      },
      {
        path: '/audit/instructionsView',
        component: () => import('@/views/Audit/component/instructionsView.vue')
      },
      {
        path: '/audit/regulationsView',
        component: () => import('@/views/Audit/component/regulationsView.vue')
      },
      {
        path: '/audit/taskView',
        component: () => import('@/views/Audit/component/taskView.vue')
      },
      {
        path: '/audit/leaveView',
        component: () => import('@/views/Audit/component/leaveView.vue')
      },
      {
        path: '/audit/craftView',
        component: () => import('@/views/Audit/component/craftView.vue')
      },
      {
        path: '/audit/drawingView',
        component: () => import('@/views/Audit/component/drawingView.vue')
      },
      {
        path: '/audit/recruitView',
        component: () => import('@/views/Audit/component/recruitView.vue')
      },
      {
        path: '/audit/overtimeView',
        component: () => import('@/views/Audit/component/overtimeView.vue')
      },
      {
        path: '/audit/departView',
        component: () => import('@/views/Audit/component/departView.vue')
      },
      {
        path: '/audit/salesContract',
        component: () => import('@/views/Audit/component/salesContract.vue')
      },
      {
        path: '/audit/stockView',
        component: () => import('@/views/Audit/component/stockView.vue')
      },
      {
        path: '/audit/applyForView',
        component: () => import('@/views/Audit/component/applyForView.vue')
      },
      {
        path: '/audit/buyListView',
        component: () => import('@/views/Audit/component/buyListView.vue')
      },
      {
        path: '/audit/purchaseOrdeView',
        component: () => import('@/views/Audit/component/purchaseOrdeView.vue')
      },
      {
        path: '/audit/expenseAccountView',
        component: () => import('@/views/Audit/component/expenseAccountView.vue')
      },
      {
        path: '/audit/InvoiceView',
        component: () => import('@/views/Audit/component/InvoiceView.vue')
      },
      {
        path: '/audit/appleforView',
        component: () => import('@/views/Audit/component/appleforView.vue')
      },
      {
        path: '/audit/loanView',
        component: () => import('@/views/Audit/component/loanView.vue')
      },
      {
        path: '/audit/transferRecordsView',
        component: () => import('@/views/Audit/component/transferRecordsView.vue')
      },
      {
        path: '/audit/giveBackView',
        component: () => import('@/views/Audit/component/giveBackView.vue')
      },
      {
        path: '/audit/drawView',
        component: () => import('@/views/Audit/component/drawView.vue')
      },
      {
        path: '/audit/paymentView',
        component: () => import('@/views/Audit/component/paymentView.vue')
      },
      {
        path: '/audit/transferView',
        component: () => import('@/views/Audit/component/transferView.vue')
      },
      {
        path: '/audit/restView',
        component: () => import('@/views/Audit/component/restView.vue')
      },
      {
        path: '/audit/businessView',
        component: () => import('@/views/Audit/component/businessView.vue')
      },
      {
        path: '/audit/productionPlanView',
        component: () => import('@/views/Audit/component/productionPlanView.vue')
      },
      {
        path: '/audit/specialView',
        component: () => import('@/views/Audit/component/specialView.vue')
      },
      {
        path: '/audit/entrustView',
        component: () => import('@/views/Audit/component/entrustView.vue')
      },
	  {
	    path: '/audit/goodsView',
	    component: () => import('@/views/Audit/component/goodsView.vue')
	  },
	  {
	    path: '/audit/saleOrderView',
	    component: () => import('@/views/Audit/component/saleOrderView.vue')
	  },
	  {
	    path: '/audit/repairView',
	    component: () => import('@/views/Audit/component/repairView.vue')
	  },
	  //维修单
	  {
	    path: '/repair',
	    component: () => import("@/views/AfterSales/repairList/list.vue")
	  },
	  {
	    path: '/repair/view',
	    component: () => import("@/views/AfterSales/repairList/view.vue")
	  },
    ]
  },
  {
    path: '/search',
    component: () => import(/* webpackChunkName: "index_search" */ '@/views/Search/index_search.vue')
  },
  {
    path: '/searchresult/:keyword',
    component: () => import(/* webpackChunkName: "SearchResult" */ '@/views/Search/SearchResult')
  },
  { // 文章详情
    path: '/detail',
    component: () => import(/* webpackChunkName: "articalDetail_index" */ '@/views/ArticalDetail/articalDetail_index.vue')
  },
  { // 编辑用户资料
    path: '/user_edit',
    component: () => import(/* webpackChunkName: "UserEdit" */ '@/views/User/UserEdit.vue')
  },
  { // 小思
    path: '/chat',
    component: () => import(/* webpackChunkName: "chat_index" */ '@/views/Chat/chat_index.vue')
  }
]

// const router = new VueRouter({
//   routes
// })

const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
// 需求：若当前有token时，禁止到登陆页
// 思路：用路由守卫进行判断当前去login的时候是否有token
// router.beforeEach((to, from, next) => {
//   if (getToken()?.length > 0 && to.path === '/login') {
//     // 有token且是去login页，不放行
//     //next(false)
//     next('/layout/home')
//   } else {
//     next() // 其他情况放行
//   }
// })
export default router
